#__next {
  height: 100%;

  .def-br-styles {

    s,
    strike {
      text-decoration: line-through;
    }

    cite,
    dfn,
    em,
    i,
    var {
      font-style: italic;
    }

    b,
    strong {
      font-weight: bold;
    }

    u,
    ins {
      text-decoration: underline;
    }

    ol,
    ul {
      padding-left: 30px;

      li {
        list-style: initial;
      }
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fullscreen {
  @include fullpage;
}

@-webkit-keyframes expand {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes expand {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.appointment-block {
  max-width: 100%;
  text-align: left;

  label {
    max-width: 100%;
    text-align: left;
  }
}

.buttonBg.expand {
  animation: expand 2s infinite;
}

#legal-note {
  right: calc(var(--menu-width) + var(--pano-close-btn-width));
  transition: all .3s ease;

  @media screen and (max-width: 768px) {
    right: 0;
  }

}

.base-tour {
  font-family: var(--custom-font-family, 'Heebo', sans-serif);
  overflow: hidden;

  .ui-wrap {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    > * {
      pointer-events: auto;
    }
  }

  >div {
    @include fullpage;
  }

  .ticketing-wrap {
    height: 100vh;
  }

  .svg-inline--fa {
    height: 15px;
    width: auto !important;
  }

  i {
    font-size: 14px;
    height: auto !important;
    width: auto !important;
  }

  .tourLogin {
    background: #000;
    overflow: hidden;
    position: relative;
    padding: 50px 0;
    overflow: hidden;
    height: 100vh;

    img {
      width: 200px;
      left: auto;
      top: auto;
      position: relative;
      display: block;
      max-height: 200px;
      margin: 0 auto 20px;
    }

    form {
      @include superCenter(absolute);
      width: 300px;
      height: 300px;
    }

    input {
      width: 100%;
      height: 50px;
      @include hCenter(relative);
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      @include hCenter(relative);
      background: var(--color-primary);
    }
  }

  .buttonsBg {
    position: absolute;
    right: 0;
    margin-top: 80px;
    transition: all 0.3s ease;
    z-index: 10;

    @media screen and (max-width: 768px) {
      margin-top: 60px;
    }

    @media screen and (orientation: landscape) and (max-width: 900px) {
      margin-top: 50px;
    }

    &.open {
      right: 250px;
    }
  }

  .buttonBg {
    position: relative;
    padding: 13px;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    top: 0;
    right: 0;
    display: block;
    margin-bottom: 10px;

    .hamburger:focus {
      outline: none;
    }

    svg,
    i {
      height: 20px;
      color: #fff;
      vertical-align: middle;
      margin: 2px 10px;
      font-size: 18px;
      transition: all 0.3s ease;
    }

    &.active {
      background: var(--color-secondary);

      &:hover {
        background: #fff;
      }

      svg,
      i {
        color: #fff;
      }
    }

    &:hover svg,
    &:hover i {
      color: var(--color-secondary);
    }

    p {
      color: #fff;
      font-size: 1em;
      margin: 0 15px;

      &:hover {
        color: var(--color-primary);
      }
    }

    a {
      color: #fff;
      font-size: 1.2em;
      margin: 0 15px;

      &:hover {
        color: var(--color-secondary);
      }
    }
  }

  @include fullpage;
  position: relative;

  &.preview {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 100px);
    margin-top: 20px;

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    nav {
      height: 100%;
      position: absolute;
    }
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    transition: all 0.3s ease;
    backdrop-filter: blur(15px);
  }

  .overlay.open {
    display: block;
  }

  nav:not([class*='Mui']) {
    position: absolute;
    right: -250px;
    width: 250px;
    top: 60px;
    z-index: 110;
    transition: all 0.3s ease;
    padding-top: 20px;
    background: rgba(0, 0, 0, 0.6);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    h2 {
      color: #fff;
      font-size: 1.1em;
      font-weight: normal;
      font-style: normal;
      margin-left: 25px;
      margin-bottom: 30px;
      text-transform: uppercase;
    }

    &.rtl {
      text-align: right;
      direction: rtl;

      h2 {
        margin-right: 25px;
        margin-left: 0;
      }

      #book,
      .appointmentButton,
      .contactButton {
        i {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }

    #book,
    .appointmentButton,
    .contactButton {
      width: 200px;
      position: relative;
      right: 0;
      left: 0;
      margin: 0 auto;
      text-decoration: none;
      margin-bottom: 20px;
      border-radius: 6px;
      padding: 10px 0;
      color: #fff;
      font-size: 0.9em;
      display: block;
      text-align: center;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: var(--color-secondary);
      }

      i {
        margin-right: 10px;
      }
    }

    .appointmentButton {
      background-color: var(--color-secondary);
    }

    .contactButton {
      background-color: var(--color-primary);
    }

    &.open {
      right: 0;
    }

    li {
      display: block;
      font-size: 1em;
      width: 100%;
      padding: 8px 23px;
      color: #fff;
      border-left: 2px solid transparent;
      position: relative;
      margin: 0 auto;
      transition: color 0.3s ease;
      line-height: 1.4;
      cursor: pointer;

      // &:hover {
      //   color: var(--color-secondary) !important;
      // }

      a {
        display: block;
        height: 100%;
      }
    }

    .sublinks,
    .sub_sublinks {
      margin-left: 10px;

      li {
        color: #ccc;

        // &:hover {
        //   color: #fff !important;
        // }
      }
    }

    #menu {
      max-height: 40vh;
      overflow-y: auto;
      font-size: 0.9em;
      margin-bottom: 20px;

      &::-webkit-scrollbar-track {
        background: var(--color-primary);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        // background: var(--color-secondary);
      }

      &::-webkit-scrollbar {
        width: var(--scroll-bar-size);
        background: var(--color-primary);
      }

      .fa-caret-down {
        color: #fff;
        float: right;
        position: relative;
        top: 2px;
      }

      &.rtl {
        text-align: right;
        direction: rtl;

        .fa-caret-down {
          float: left;
        }
      }

      .mainLink {
        user-select: cursor;

        ul {
          display: none;
          margin-top: 8px;
          padding-bottom: 0;
        }

        &.open {
          background: var(--color-secondary);
          color: #fff !important;

          >svg,
          >i {
            transform: rotate(180deg);
          }
        }
      }

      .closed {
        display: none;
      }
    }

    .contact {
      text-align: left;
      padding: 0 25px;
      position: relative;
      width: 100%;
      margin: 20px 0;

      &.rtl span {
        text-align: right;
      }

      &.rtl span i {
        margin-left: 5px;
        margin-right: 0;
      }

      &.rtl img {
        margin-right: 10px;
        margin-left: 0;
      }

      span {
        display: block;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        font-size: 12px;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover,
        &:hover i {
          color: var(--color-secondary);
        }

        i {
          margin-right: 5px;
          transition: all 0.3s ease;
        }
      }

      h5 {
        color: #fff;
        text-align: center;
        display: block;
        font-size: 12px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      img {
        height: 30px;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }

  .inner {
    height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.3s ease;
    overflow: hidden;

    &.mobile {
      @include mobileHeight;
    }

    &.isChromeInIOS {
      height: 100vh;
      max-height: 100vh;
    }

  }

  .logo {
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 20px;
    width: 100px;

    &.width {
      width: 200px;
    }

    @media screen and (max-width: 768px) {
      width: 70px;

      &.width {
        width: 140px;
      }

      top: 10px;
    }
  }

  .lock-layer {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  iframe {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.25s ease;
  }

  iframe.showcase {
    z-index: 1;
  }

  header .fa-phone,
  header .fa-envelope {
    color: #000;
    font-size: 15px;
    position: relative;
  }

  header .fa-clock,
  header .fa-envelope {
    color: #000;
    font-size: 15px;
    position: relative;
  }

  header .fa-envelope {
    margin-left: 15px;
  }

  .youtube {
    height: 200px;
    width: 350px;
    position: absolute;
    bottom: 0;
    left: 60px;
    z-index: 100;
  }

  @media screen and (max-width: 768px) {
    #currentRoom {
      display: none;
    }
  }

  .youtube .fa-times {
    position: absolute;
    right: -10px;
    top: -10px;

    background: #000;
    border-radius: 50%;
    color: #fff;
    padding: 8px;
    font-size: 14px;
    line-height: 10px;
    z-index: 2;
  }

  @media screen and (max-width: 768px) {
    .youtube {
      width: 250px;
      height: 150px;
      right: 10px;
    }
  }

  #ytplayer {
    height: 100%;
    width: 100%;
  }

  .popup {
    background: #fff;
    height: 90%;
    width: 90%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 8px;

    .close {
      position: absolute;
      float: none;
      z-index: 3;
      top: -15px;
      right: -15px;
      padding: 0;
      opacity: 1;
      font-size: 14px;
      cursor: pointer;
      background: #fff;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      line-height: 38px;
      text-align: center;

      &:hover {

        svg,
        i {
          color: var(--color-secondary);
        }
      }

      svg,
      i {
        color: #aaa;
        font-size: 20px;
        transition: all 0.3s ease;
      }
    }

    h2 {
      font-size: 1.4em;
      padding: 20px 0;
      text-align: center;
    }

    .square-button {
      margin-top: 20px;
      text-align: center;
    }

    input {
      border-bottom: 1px solid #ccc;
    }

    iframe {
      height: 100% !important;
      width: 100% !important;
      position: relative;
      top: 0;
      left: 0;
      border-radius: 8px;
    }

    &.rtl {
      text-align: right;

      input {
        text-align: right;
      }
    }
  }

  #external-popup {
    z-index: 111;
  }

  #contact-pop {
    max-height: 550px;
    max-width: 600px;

    .contact-popup-content {
      overflow-y: auto;
      height: 100%;
      padding: 30px;
    }

    @media screen and (max-width: 768px) {
      max-height: 100vh;
      height: 92%;
    }

    @media screen and (max-width: 768px) and (orientation: landscape) {
      overflow-y: auto;
      overflow-x: hidden;

      .close {
        top: 0;
        right: 0;
      }
    }

    .appointment-block label {
      font-size: 12px;
    }

    h2 {
      text-align: left;
      font-weight: normal;
      font-style: normal;
      padding: 0;
      font-size: 18px;
      margin-top: -10px;
      margin-bottom: 30px;
    }

    .input-c {
      width: 49%;
      margin-bottom: 10px;

      &.full {
        width: 100%;
      }

      label {
        font-weight: normal;
      }

      input,
      textarea {
        border: 1px solid #eee;
      }

      textarea {
        height: 100px;
      }
    }

    .square-button {
      background: var(--color-secondary);
      color: #fff;
      width: 120px;
      float: right;
      margin-top: 0;
    }

    .agent-cards {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .agent-card {
        flex-basis: 50%;

        @media screen and (max-width: 768px) {
          width: 100% !important;
          flex-basis: 100%;
        }

        &:first-child {
          margin-left: 0;
        }

        .img {
          background-size: cover;
          background-repeat: no-repeat;
          height: 60px;
          width: 60px;
          border-radius: 50%;
          background-position: center;
          display: inline-block;
          vertical-align: middle;
        }

        .text {
          display: inline-block;
          margin-left: 10px;
          vertical-align: middle;
          width: calc(100% - 70px);

          h4 {
            font-size: 16px;
            margin-bottom: 2px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
          }

          a {
            color: var(--color-secondary);
          }
        }
      }
    }
  }

  .adPop {
    max-height: 100vh;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    right: 0;
    bottom: 0;
    margin: auto;

    .img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    form {
      width: 100%;
      height: auto;
      max-height: 250px;
      overflow-y: auto;
      padding-top: 10px;
      background: #fff;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 10px;

      @media screen and (max-width: 768px) {
        padding: 10px 20px;
      }

      &.rtl input {
        text-align: right;
      }

      .square-button {
        width: calc(90% + 40px);
        clear: both;
        margin-top: 15px;
        background: var(--color-secondary);
        color: #fff;

        &:hover {
          background: var(--color-primary);
        }
      }

      input {
        width: 30%;
        clear: none;
        display: inline-block;
        margin: 0 10px;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }

  form input:not(#intro-screen input),
  form textarea:not(#intro-screen textarea) {
    height: 40px;
    clear: both;
    width: 80%;
    position: relative;
    margin: 10px auto;
    right: 0;
    left: 0;
    padding: 5px;
    text-indent: 6px;
    font-size: 0.9em;
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    display: block;
    font-family: "Open Sans Hebrew", sans-serif;
  }

  form textarea {
    height: 80px;
  }

  .mapPop iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  form button:not(#intro-screen button) {
    background: #58b504;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    float: right;
    margin-right: 10%;
    margin-top: 10px;
    padding: 10px 40px;
    font-size: 0.9em;
  }

  form p:not(#intro-screen p) {
    line-height: 450px;
    text-align: center;
    font-size: 1.4em;
    position: absolute;
    top: 0;
    width: 100%;
  }

  form input.error:not(#intro-screen input.error) {
    border: 1px solid red;
  }

  .fa-map-marked {
    right: 10px;
    bottom: 90px;
    left: auto;

    &.active {
      color: var(--color-secondary);
    }
  }

  .autocadPop {
    height: 100px;
    text-align: center;
  }

  .tagPop {
    height: 300px;
    width: 450px;
    max-height: 100vh;
    max-width: 100vw;

    .fa-compress,
    .fa-expand {
      bottom: 10px;
      right: 10px;
    }

    iframe {
      @include fullpage;
    }
  }

  .sharePop {
    height: 150px;
    text-align: center;
    padding: 20px 0;
    width: 320px;

    @media screen and (max-width: 768px) {
      width: 360px;
    }

    .icon {
      color: #fff;
      background: var(--color-secondary);
      cursor: pointer;
      border-radius: 50%;
      padding: 10px;
      margin: 0 10px;
      display: inline-block;
      font-size: 1.7em;

      &:hover {
        color: var(--color-primary);
      }
    }

    label {
      display: block;
      margin-top: 20px;

      >div {
        top: 10px;
        margin-left: 10px;
        position: relative;
      }
    }
  }

  .autocadPop h4 {
    padding: 10px 0;
    font-size: 1.1em;
  }

  .autocadPop a {
    background: #000;
    margin: 0 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.9em;
    color: #fff;
    padding: 10px;
    margin-top: 10px;
    display: inline-block;
  }

  .autocadPop a:hover {
    background: #242424;
  }
}

.intro-screen {
  $bp-max-width-md: 768px;
  $bp-max-width-md-2: 550px;
  $bp-max-width-sm: 375px;
  $bp-max-height-sm: 569px;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  background-size: cover;
  background-position: center;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;

  #help-modal {
    background-color: rgba(0, 0, 0, .9) !important;

    .tabs {
      position: absolute;
      width: 100%;
    }

    .icon-button.button-interactive {
      top: 15px;
      right: 10px
    }

    .more-help-tab,
    .navigation-tab {
      cursor: pointer;
      padding: 15px !important
    }
  }

  .slick-slide {
    &>div {
      display: flex;
      justify-content: center;
    }
  }

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: -1;
  }

  h1 {
    color: white;
    font-style: normal;
    font-size: 60px;
    font-weight: 700;
    max-width: 800px;
    text-align: center;

    @media screen and (max-width: $bp-max-width-md) {
      max-width: 80%;
      font-size: 40px;
    }

    @media screen and (max-width: $bp-max-width-md-2) {
      max-width: 90%;
      font-size: 30px;
    }

    @media screen and (max-height: $bp-max-height-sm) and (max-height: $bp-max-height-sm) {
      font-size: 20px;
    }
  }

  h3 {
    font-style: normal;
    font-size: 14px;
    color: white;
    margin-bottom: 60px;
    max-width: 800px;
    text-align: center;

    @media screen and (max-width: $bp-max-width-md) {
      max-width: 80%;
    }

    @media screen and (max-width: $bp-max-width-md-2) {
      max-width: 90%;
      font-size: 12px;
    }

    @media screen and (max-height: $bp-max-height-sm) and (max-height: $bp-max-height-sm) {
      font-size: 12px;
      margin-bottom: 30px;
    }
  }

  p {
    color: white;
  }

  .powered-by {
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      margin-top: auto;
      margin-bottom: 5px;
    }
  }

  .tour-play-btn {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
    background: rgba(0, 0, 0, 0.3);
    border: 2px solid white;
    color: white;
    border-radius: 50%;
    font-size: 30px;
    margin-bottom: 10px;
    padding-left: 24px;
    padding-right: 18px;
    position: relative;

    &:focus {
      outline: none;
    }

    @media screen and (max-width: $bp-max-width-sm) {
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
      font-size: 40px;
    }

    @media screen and (max-height: $bp-max-height-sm) {
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
      font-size: 40px;
      margin-bottom: 30px;
    }
  }

  .loader-animation {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
    position: relative;
    color: white;
    line-height: 100px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;

    &:after {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      top: 0;
      left: 0;
    }

    @media screen and (max-width: $bp-max-width-sm) {
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
      line-height: 100px;
    }

    @media screen and (max-height: $bp-max-height-sm) {
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
      line-height: 100px;
      margin-bottom: 30px;
    }

    .loader-title {
      font-size: 12px;
      color: white;
      line-height: 100px;
      text-align: center;

      @media screen and (max-width: $bp-max-width-sm) {
        line-height: 100px;
        font-size: 12px;
      }

      @media screen and (max-height: $bp-max-height-sm) {
        line-height: 100px;
        font-size: 12px;
      }
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .client-logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-top: 40px;

    @media screen and (max-width: $bp-max-width-md) {
      width: 100px;
      height: 100px;
    }

    @media screen and (max-width: $bp-max-width-sm) {
      width: 60px;
      height: 60px;
    }

    @media screen and (max-height: $bp-max-height-sm) {
      width: 60px;
      height: 60px;
      margin-top: 30px !important;
    }
  }

  .logo {
    width: 100px;
  }

  .logo-divider {
    color: white;
  }

  .mp-logo {
    height: 18px;
    margin-bottom: 3px;
  }
}

.timer {
  z-index: 2;
  position: absolute;
  color: black;
  margin: 0px;
  right: 0;
  top: 44px;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  color: white;
}

.middleContainer {
  padding: 1rem;
  color: white;
  padding-top: 0px;
}

.noPadding {
  padding: 0px;
}


@mixin input-range {
  input[type=range] {
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
    padding: 0;
    margin: 0;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    background: #ffffff;
    width: 100%;
    height: 5px;
    cursor: pointer;
    opacity: 1;
  }

  input[type=range]::-webkit-slider-thumb {
    margin-top: -5px;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
  }

  input[type=range]::-moz-range-track {
    background: #ffffff;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }

  input[type=range]::-moz-range-thumb {
    margin-top: -7px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    width: 100%;
    height: 5px;
    cursor: pointer;
  }

  input[type=range]::-ms-fill-lower {
    background: #ffffff;
  }

  input[type=range]::-ms-fill-upper {
    background: #ffffff;
  }

  input[type=range]::-ms-thumb {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #ffffff;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
  }
}

.sounds {
  position: absolute;
  z-index: 999;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 220px;
  height: 53px;
  opacity: 1;
  background-color: #5e5646;
  border-radius: 5px;
  padding: 0px 10px;

  &>.container-controls {
    position: absolute;
    display: flex;
    right: 0;
    top: -17px;
    background: #5e5646;
    padding: 3px 7px;
    border-radius: 4px;
    opacity: 1;

    .fas {
      font-size: 15px;
      color: white;
      padding: 2px 6px;

      &:hover {
        color: rgb(143, 139, 139);
        cursor: pointer;
      }
    }
  }

  .sound-range {
    @include input-range;
    margin-top: -5px;
  }

  .sound-controls {
    display: flex;
    flex-direction: row;
    color: #fff;
    justify-content: space-between;
    margin-top: -5px;

    .left-side {
      display: flex;
      flex-direction: row;
      height: 15px;

      >div {
        margin-right: 10px;

        &:hover {
          opacity: 0.5;
        }
      }

      .volume {
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include input-range;

        input[type=range] {
          height: 11px;
          margin-left: 10px;
        }

        input[type=range]::-webkit-slider-thumb {
          margin-top: -4px;
          width: 12px;
          height: 12px;
        }
      }

    }
  }
}

.rtl-direction {
  direction: rtl;
}

.rtl-text-align {
  text-align: right;
  direction: rtl;
}

.sound-volume {
  position: relative;
  z-index: 999;
  bottom: 145px;
  left: 0;
  width: 40px;
  height: 200px;
  opacity: 0.5;
  background-color: var(--color-primary);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 10px 0;

  .sound-volume-range {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 85%;

    i {
      font-size: 20px;
      cursor: pointer;
    }

    input[type=range] {
      height: 70px;
      width: 100px;
      background-color: transparent;
      -webkit-appearance: none;
      padding: 0;
      margin: 20px 0;

      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }

    input[type=range]:focus {
      outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
      background: #ffffff;
      width: 100%;
      height: 5px;
      cursor: pointer;
      opacity: 1;
    }

    input[type=range]::-webkit-slider-thumb {
      margin-top: -7px;
      width: 18px;
      height: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      -webkit-appearance: none;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
      background: #ffffff;
    }

    input[type=range]::-moz-range-track {
      background: #ffffff;
      width: 100%;
      height: 5px;
      cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
      margin-top: -7px;
      width: 18px;
      height: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      -webkit-appearance: none;
    }

    input[type=range]::-ms-track {
      background: transparent;
      border-color: transparent;
      color: transparent;
      width: 100%;
      height: 5px;
      cursor: pointer;
    }

    input[type=range]::-ms-fill-lower {
      background: #ffffff;
    }

    input[type=range]::-ms-fill-upper {
      background: #ffffff;
    }

    input[type=range]::-ms-thumb {
      width: 18px;
      height: 18px;
      background: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      margin-top: 0;
    }

    input[type=range]:focus::-ms-fill-lower {
      background: #ffffff;
    }

    input[type=range]:focus::-ms-fill-upper {
      background: #ffffff;
    }
  }

  .sound-controls {
    color: var(--color-secondary);
  }

  @media screen and (max-width: 450px) {
    bottom: 200px;
  }
}

.orderModal {
  max-width: calc(100% - 43px);
  max-height: 100%;
  width: 860px;
  height: 617px;
}

.cVideo-modal-main {
  max-height: 100%;
  max-width: calc(100% - 43px);

  .ticketingModal-buttonWrap button {
    margin-bottom: 20px;
  }
}

.Toastify__toast-container.Toastify__toast-container--bottom-center {
  z-index: 999999;
}

.red {
  color: red;
}

.react-confirm-alert-overlay {
  background: transparent !important;
}
